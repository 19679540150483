"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STAGGER = exports.SEQUENCE = exports.PARALLEL = exports.EXITED = exports.EXITING = exports.ENTERED = exports.ENTERING = void 0;
exports.ENTERING = 'entering';
exports.ENTERED = 'entered';
exports.EXITING = 'exiting';
exports.EXITED = 'exited';
exports.PARALLEL = 'parallel';
exports.SEQUENCE = 'sequence';
exports.STAGGER = 'stagger';
