"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Blockquote = void 0;
var animation_1 = require("@arwes/animation");
var Blockquote_component_1 = require("./Blockquote.component");
var classAnimatorSettings = {
    manager: 'stagger'
};
var Blockquote = animation_1.withAnimator(classAnimatorSettings)(Blockquote_component_1.Blockquote);
exports.Blockquote = Blockquote;
