"use strict";
/* eslint-disable @typescript-eslint/explicit-function-return-type */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extendAnimator = void 0;
// TODO: Review and analyze if the extension functionality is necessary or
// if it can be removed, since it creates excesive typing overload and
// decreases performance.
// TODO: Test cases are not correctly checking typing.
var react_1 = require("react");
var mergeClassAndInstanceAnimatorSettings_1 = require("../utils/mergeClassAndInstanceAnimatorSettings");
function extendAnimator(extendedClassAnimator) {
    var extendAnimatorWrapper = function (InputComponent) {
        var OutputComponent = react_1.forwardRef(function (props, ref) {
            var instanceAnimator = props.animator, otherProps = __rest(props, ["animator"]);
            var resultAnimator = mergeClassAndInstanceAnimatorSettings_1.mergeClassAndInstanceAnimatorSettings(extendedClassAnimator, instanceAnimator);
            return react_1.createElement(InputComponent, __assign(__assign({}, otherProps), { animator: resultAnimator, ref: ref }));
        });
        var componentName = InputComponent.displayName || InputComponent.name || 'Component';
        OutputComponent.displayName = "extendAnimator(" + componentName + ")";
        return OutputComponent;
    };
    return extendAnimatorWrapper;
}
exports.extendAnimator = extendAnimator;
