"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.THEME_FACTOR_MULTIPLIERS_NAMES = exports.THEME_TRANSITION_DURATION_DEFAULT = exports.THEME_SHADOW_SPREAD_DEFAULT = exports.THEME_SHADOW_BLUR_DEFAULT = exports.THEME_OUTLINE_DEFAULT = exports.THEME_SPACE_DEFAULT = exports.THEME_FONT_SCALE_DEFAULT = exports.THEME_PALETTE_ELEVATION_OFFSET_DEFAULT = exports.THEME_PALETTE_TONAL_OFFSET_DEFAULT = exports.THEME_BREAKPOINTS_DEFAULT = exports.THEME_BREAKPOINTS_KEYS = void 0;
exports.THEME_BREAKPOINTS_KEYS = Object.freeze(['xs', 'sm', 'md', 'lg', 'xl']);
exports.THEME_BREAKPOINTS_DEFAULT = Object.freeze({
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
});
exports.THEME_PALETTE_TONAL_OFFSET_DEFAULT = 0.1;
exports.THEME_PALETTE_ELEVATION_OFFSET_DEFAULT = 0.025;
exports.THEME_FONT_SCALE_DEFAULT = 1;
exports.THEME_SPACE_DEFAULT = 5;
exports.THEME_OUTLINE_DEFAULT = 1;
exports.THEME_SHADOW_BLUR_DEFAULT = 1;
exports.THEME_SHADOW_SPREAD_DEFAULT = 1;
exports.THEME_TRANSITION_DURATION_DEFAULT = 100;
exports.THEME_FACTOR_MULTIPLIERS_NAMES = Object.freeze([
    'fontScale',
    'space',
    'outline',
    'shadowBlur',
    'shadowSpread',
    'transitionDuration'
]);
