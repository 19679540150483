"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrameLines = void 0;
var css_1 = require("@emotion/css");
var react_1 = require("@emotion/react");
var useBleepsOnAnimator_1 = require("../utils/useBleepsOnAnimator");
var Frame_1 = require("../utils/Frame");
function FrameLines(props) {
    var animator = props.animator, className = props.className, largeLineWidth = props.largeLineWidth, smallLineWidth = props.smallLineWidth, smallLineLength = props.smallLineLength, hideTopLines = props.hideTopLines, hideBottomLines = props.hideBottomLines, otherProps = __rest(props, ["animator", "className", "largeLineWidth", "smallLineWidth", "smallLineLength", "hideTopLines", "hideBottomLines"]);
    useBleepsOnAnimator_1.useBleepsOnAnimator({
        entering: 'assemble',
        exiting: 'assemble'
    });
    var theme = react_1.useTheme();
    var llWidth = theme.outline(largeLineWidth);
    var slWidth = theme.outline(smallLineWidth);
    var slLength = smallLineLength;
    // Large Polylines.
    var largePolylines = [];
    if (!hideTopLines) {
        largePolylines = [
            [[0, 0], ['50% + 0.1', 0]],
            [['100%', 0], ['50% - 0.1', 0]]
        ];
    }
    if (!hideBottomLines) {
        largePolylines = largePolylines.concat([
            [[0, '100%'], ['50% + 0.1', '100%']],
            [['100%', '100%'], ['50% - 0.1', '100%']]
        ]);
    }
    // Small Polylines.
    var smallPolylines = [];
    if (!hideTopLines) {
        smallPolylines = [
            [[0, llWidth], [slLength, llWidth]],
            [['100%', llWidth], ["100% - " + slLength, llWidth]]
        ];
    }
    if (!hideBottomLines) {
        smallPolylines = smallPolylines.concat([
            [[0, "100% - " + llWidth], [slLength, "100% - " + llWidth]],
            [['100%', "100% - " + llWidth], ["100% - " + slLength, "100% - " + llWidth]]
        ]);
    }
    return (react_1.jsx(Frame_1.Frame, __assign({}, otherProps, { className: css_1.cx('arwes-frame-lines', className), shapes: [
            [
                [0, 0],
                [0, '100%'],
                ['100%', '100%'],
                ['100%', 0]
            ]
        ], polylines: __spreadArrays(largePolylines.map(function (polyline) { return ({
            polyline: polyline,
            lineWidth: llWidth
        }); }), smallPolylines.map(function (polyline) { return ({
            polyline: polyline,
            lineWidth: slWidth
        }); })) })));
}
exports.FrameLines = FrameLines;
FrameLines.defaultProps = {
    largeLineWidth: 1,
    smallLineWidth: 1,
    smallLineLength: 10
};
