"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChildrenNodesSequenceActivationTimes = void 0;
function getChildrenNodesSequenceActivationTimes(nodes) {
    var duration = 0;
    var times = nodes.reduce(function (items, node, index) {
        var nodeDuration = node.getDuration();
        var offset = nodeDuration.offset || 0;
        var time = offset;
        if (index !== 0) {
            var prevItem = items[index - 1];
            time = prevItem.time + prevItem.node.getDuration().enter + offset;
        }
        duration = time + nodeDuration.enter;
        var item = { node: node, time: time };
        return __spreadArrays(items, [item]);
    }, []);
    return { duration: duration, times: times };
}
exports.getChildrenNodesSequenceActivationTimes = getChildrenNodesSequenceActivationTimes;
