"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BLEEPS_CATEGORIES = exports.BLEEPS_NOTIFICATION = exports.BLEEPS_INTERACTION = exports.BLEEPS_TRANSITION = exports.BLEEPS_BACKGROUND = void 0;
exports.BLEEPS_BACKGROUND = 'background';
exports.BLEEPS_TRANSITION = 'transition';
exports.BLEEPS_INTERACTION = 'interaction';
exports.BLEEPS_NOTIFICATION = 'notification';
exports.BLEEPS_CATEGORIES = [
    exports.BLEEPS_BACKGROUND,
    exports.BLEEPS_TRANSITION,
    exports.BLEEPS_INTERACTION,
    exports.BLEEPS_NOTIFICATION
];
