"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrameCorners = void 0;
var css_1 = require("@emotion/css");
var react_1 = require("@emotion/react");
var useBleepsOnAnimator_1 = require("../utils/useBleepsOnAnimator");
var Frame_1 = require("../utils/Frame");
function FrameCorners(props) {
    var animator = props.animator, className = props.className, cornerWidth = props.cornerWidth, cornerLength = props.cornerLength, showContentLines = props.showContentLines, contentLineWidth = props.contentLineWidth, otherProps = __rest(props, ["animator", "className", "cornerWidth", "cornerLength", "showContentLines", "contentLineWidth"]);
    useBleepsOnAnimator_1.useBleepsOnAnimator({
        entering: 'assemble',
        exiting: 'assemble'
    });
    var theme = react_1.useTheme();
    var cw = theme.outline(cornerWidth);
    var cl = cornerLength;
    var contentPolylines = [];
    if (showContentLines) {
        var yAnimated = {
            initialStyles: { transform: 'scaleY(0)' },
            entering: { scaleY: 1 },
            exiting: { scaleY: 0 }
        };
        var xAnimated = {
            initialStyles: { transform: 'scaleX(0)' },
            entering: { scaleX: 1 },
            exiting: { scaleX: 0 }
        };
        contentPolylines = [
            {
                polyline: [[cw, cw], [cw, "100% - " + cw]],
                animated: yAnimated
            },
            {
                polyline: [["100% - " + cw, cw], ["100% - " + cw, "100% - " + cw]],
                animated: yAnimated
            },
            {
                polyline: [[cw, cw], ["100% - " + cw, cw]],
                animated: xAnimated
            },
            {
                polyline: [[cw, "100% - " + cw], ["100% - " + cw, "100% - " + cw]],
                animated: xAnimated
            }
        ].map(function (contentLine) { return (__assign(__assign({}, contentLine), { lineWidth: theme.outline(contentLineWidth), css: { transformOrigin: 'center', opacity: 0.5 } })); });
    }
    var cornerPolylines = [
        [[0, 0], [0, cl]],
        [[0, 0], [cl, 0]],
        [['100%', 0], ["100% - " + cl, 0]],
        [['100%', 0], ['100%', cl]],
        [['100%', '100%'], ["100% - " + cl, '100%']],
        [['100%', '100%'], ['100%', "100% - " + cl]],
        [[0, '100%'], [0, "100% - " + cl]],
        [[0, '100%'], [cl, '100%']]
    ].map(function (polyline) { return ({
        polyline: polyline,
        css: { strokeLinecap: 'square' }
    }); });
    return (react_1.jsx(Frame_1.Frame, __assign({}, otherProps, { className: css_1.cx('arwes-frame-corners', className), shapes: [
            [
                [cw, cw],
                [cw, "100% - " + cw],
                ["100% - " + cw, "100% - " + cw],
                ["100% - " + cw, cw]
            ]
        ], polylines: __spreadArrays(contentPolylines, cornerPolylines), lineWidth: cw })));
}
exports.FrameCorners = FrameCorners;
FrameCorners.defaultProps = {
    cornerWidth: 1,
    cornerLength: 10,
    contentLineWidth: 1
};
