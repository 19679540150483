"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChildrenNodesStaggerActivationTimes = void 0;
function getChildrenNodesStaggerActivationTimes(nodes, parentDuration) {
    var accumulatedOffset = 0;
    var times = nodes.reduce(function (items, node, index) {
        accumulatedOffset += node.getDuration().offset || 0;
        var normalTime = !index ? 0 : index * parentDuration.stagger;
        var time = normalTime + accumulatedOffset;
        var item = { node: node, time: time };
        return __spreadArrays(items, [item]);
    }, []);
    var lastItem = times[times.length - 1];
    // Assuming all children nodes have the same duration.
    var duration = !times.length ? 0 : lastItem.time + lastItem.node.getDuration().enter;
    return { duration: duration, times: times };
}
exports.getChildrenNodesStaggerActivationTimes = getChildrenNodesStaggerActivationTimes;
