"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBleepsOnAnimator = void 0;
var react_1 = require("react");
var animation_1 = require("@arwes/animation");
var sounds_1 = require("@arwes/sounds");
// TODO: Add unit tests.
var useBleepsOnAnimator = function (bleepsOnTransitions) {
    var animator = animation_1.useAnimator();
    var bleeps = sounds_1.useBleeps();
    if (!animator) {
        throw new Error('Animator parent component is required.');
    }
    react_1.useEffect(function () {
        if (!animator.animate) {
            return;
        }
        return function () {
            var _a, _b;
            var enteringBleep = bleepsOnTransitions.entering;
            var exitingBleep = bleepsOnTransitions.exiting;
            (_a = bleeps[enteringBleep]) === null || _a === void 0 ? void 0 : _a.stop();
            (_b = bleeps[exitingBleep]) === null || _b === void 0 ? void 0 : _b.stop();
        };
    }, []);
    react_1.useEffect(function () {
        var _a, _b, _c, _d;
        if (!animator.animate) {
            return;
        }
        var enteringBleep = bleepsOnTransitions.entering;
        var exitingBleep = bleepsOnTransitions.exiting;
        switch (animator.flow.value) {
            case animation_1.ENTERING: {
                (_a = bleeps[enteringBleep]) === null || _a === void 0 ? void 0 : _a.play();
                break;
            }
            case animation_1.ENTERED: {
                (_b = bleeps[enteringBleep]) === null || _b === void 0 ? void 0 : _b.stop();
                break;
            }
            case animation_1.EXITING: {
                (_c = bleeps[exitingBleep]) === null || _c === void 0 ? void 0 : _c.play();
                break;
            }
            case animation_1.EXITED: {
                (_d = bleeps[exitingBleep]) === null || _d === void 0 ? void 0 : _d.stop();
                break;
            }
        }
    }, [animator.flow]);
};
exports.useBleepsOnAnimator = useBleepsOnAnimator;
