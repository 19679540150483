"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrameUnderline = void 0;
var css_1 = require("@emotion/css");
var react_1 = require("@emotion/react");
var Frame_1 = require("../utils/Frame");
var useBleepsOnAnimator_1 = require("../utils/useBleepsOnAnimator");
function FrameUnderline(props) {
    var animator = props.animator, className = props.className, lineWidth = props.lineWidth, squareSize = props.squareSize, otherProps = __rest(props, ["animator", "className", "lineWidth", "squareSize"]);
    var _a = react_1.useTheme(), space = _a.space, outline = _a.outline;
    var ss = squareSize;
    useBleepsOnAnimator_1.useBleepsOnAnimator({
        entering: 'assemble',
        exiting: 'assemble'
    });
    return (react_1.jsx(Frame_1.Frame, __assign({}, otherProps, { className: css_1.cx('arwes-frame-underline', className), css: {
            padding: space(2) + "px " + space(4) + "px"
        }, shapes: [
            [
                [0, 0],
                [0, '100%'],
                ["100% - " + ss, '100%'],
                ['100%', "100% - " + ss],
                ['100%', 0]
            ]
        ], polylines: [
            [
                [0, '100%'],
                ["100% - " + ss, '100%'],
                ['100%', "100% - " + ss]
            ]
        ], lineWidth: outline(lineWidth) })));
}
exports.FrameUnderline = FrameUnderline;
FrameUnderline.defaultProps = {
    lineWidth: 2,
    squareSize: 15
};
